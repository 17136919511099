import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ServiceMember = ({ item }) => (
  <ItemWrapper>
    <Member to={`/team/${item.slug}`}>
      <ImageBox>
        {item.acfTeam.image.localFile && (
          <Img
            fluid={item.acfTeam.image.localFile.childImageSharp.fluid}
            alt={item.title}
          />
        )}
      </ImageBox>
      <ContentBox>
        <h3>{item.title}</h3>
        <p>{item.acfTeam.education}</p>
        <ProfileButton className="button">VIEW PROFILE</ProfileButton>
      </ContentBox>
    </Member>
  </ItemWrapper>
)
export default ServiceMember

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ItemWrapper = styled.div`
  display: block;
  transition: all 0.2s ease-in-out;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  overflow: hidden;
`

const ImageBox = styled.div`
  img {
    width: 100%;
    margin-bottom: 0px;
    display: block;
  }
`

const Member = styled(Link)`
  display: block;
  &:hover {
    .button {
      background: ${(props) => props.theme.grey};
    }
    cursor: pointer;
  }
`

const ContentBox = styled.div`
  text-align: center;
  background-color: #d8d8d84d;
  padding: 20px 30px 40px 30px;
  h3 {
    font-weight: 600;
    font-size: 38px;
    margin-bottom: 10px;
    color: #fff;
  }
  p {
    color: #fff;
    font-size: 16px;
    min-height: 46px;
  }
`

const ProfileButton = styled.div`
  background-color: ${(props) => props.theme.gold};
  transition: all 300ms ease-in-out;
  color: #fff;
  padding: 13px 25px;
  border-radius: 5px;
  display: inline-block;
`
